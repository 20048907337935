import { default as index8ByfKtxdAcMeta } from "/home/forge/app.eis-s.de/releases/20241106093900/client/pages/auth/index.vue?macro=true";
import { default as indexNS796s4lATMeta } from "/home/forge/app.eis-s.de/releases/20241106093900/client/pages/auth/social/[organisation]/[provider]/index.vue?macro=true";
import { default as defectActivitiesVNGXDsgQpxMeta } from "/home/forge/app.eis-s.de/releases/20241106093900/client/pages/defects/[id]/defectActivities.vue?macro=true";
import { default as defectBaseDataAkWmEsD5fXMeta } from "/home/forge/app.eis-s.de/releases/20241106093900/client/pages/defects/[id]/defectBaseData.vue?macro=true";
import { default as _91id_93L9tQp3lH3vMeta } from "/home/forge/app.eis-s.de/releases/20241106093900/client/pages/defects/[id].vue?macro=true";
import { default as indexCNDEyx1Hz3Meta } from "/home/forge/app.eis-s.de/releases/20241106093900/client/pages/index.vue?macro=true";
import { default as reportActivities6VqECq7ahFMeta } from "/home/forge/app.eis-s.de/releases/20241106093900/client/pages/reports/[id]/reportActivities.vue?macro=true";
import { default as reportBaseDataIEUXe7j3Z4Meta } from "/home/forge/app.eis-s.de/releases/20241106093900/client/pages/reports/[id]/reportBaseData.vue?macro=true";
import { default as reportDefectsUDkz1G2KmAMeta } from "/home/forge/app.eis-s.de/releases/20241106093900/client/pages/reports/[id]/reportDefects.vue?macro=true";
import { default as _91id_93EdmymAoZvzMeta } from "/home/forge/app.eis-s.de/releases/20241106093900/client/pages/reports/[id].vue?macro=true";
import { default as languageDQjyJMXCV7Meta } from "/home/forge/app.eis-s.de/releases/20241106093900/client/pages/settings/language.vue?macro=true";
import { default as _91id_93NNPLcI6MyOMeta } from "/home/forge/app.eis-s.de/releases/20241106093900/client/pages/settings/loginProviders/[id].vue?macro=true";
import { default as indexcQYpeyMYA3Meta } from "/home/forge/app.eis-s.de/releases/20241106093900/client/pages/settings/loginProviders/index.vue?macro=true";
import { default as organisationBaseDataMx7A6vkD05Meta } from "/home/forge/app.eis-s.de/releases/20241106093900/client/pages/settings/organisations/[id]/organisationBaseData.vue?macro=true";
import { default as _91id_93UBEU2hr2mgMeta } from "/home/forge/app.eis-s.de/releases/20241106093900/client/pages/settings/organisations/[id].vue?macro=true";
import { default as indexfwBTTEMlhsMeta } from "/home/forge/app.eis-s.de/releases/20241106093900/client/pages/settings/organisations/index.vue?macro=true";
import { default as rolesUoejTZpSnhMeta } from "/home/forge/app.eis-s.de/releases/20241106093900/client/pages/settings/roles.vue?macro=true";
import { default as userActivitieszssLT4inAFMeta } from "/home/forge/app.eis-s.de/releases/20241106093900/client/pages/settings/users/[id]/userActivities.vue?macro=true";
import { default as userBaseDataaAB7OxVEaCMeta } from "/home/forge/app.eis-s.de/releases/20241106093900/client/pages/settings/users/[id]/userBaseData.vue?macro=true";
import { default as userCommunicationsR2tsMWpobDMeta } from "/home/forge/app.eis-s.de/releases/20241106093900/client/pages/settings/users/[id]/userCommunications.vue?macro=true";
import { default as _91id_93G0gY2zIqEqMeta } from "/home/forge/app.eis-s.de/releases/20241106093900/client/pages/settings/users/[id].vue?macro=true";
import { default as indexOWuC1A3DBqMeta } from "/home/forge/app.eis-s.de/releases/20241106093900/client/pages/settings/users/index.vue?macro=true";
import { default as usersn0J7foJK4nMeta } from "/home/forge/app.eis-s.de/releases/20241106093900/client/pages/settings/users.vue?macro=true";
import { default as settingsSE5J78pnFJMeta } from "/home/forge/app.eis-s.de/releases/20241106093900/client/pages/settings.vue?macro=true";
import { default as _91_46_46_46slug_93EyMchwL6wcMeta } from "/home/forge/app.eis-s.de/releases/20241106093900/client/pages/wiki/[...slug].vue?macro=true";
export default [
  {
    name: index8ByfKtxdAcMeta?.name ?? "auth",
    path: index8ByfKtxdAcMeta?.path ?? "/auth",
    meta: index8ByfKtxdAcMeta || {},
    alias: index8ByfKtxdAcMeta?.alias || [],
    redirect: index8ByfKtxdAcMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241106093900/client/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: indexNS796s4lATMeta?.name ?? "auth-social-organisation-provider",
    path: indexNS796s4lATMeta?.path ?? "/auth/social/:organisation()/:provider()",
    meta: indexNS796s4lATMeta || {},
    alias: indexNS796s4lATMeta?.alias || [],
    redirect: indexNS796s4lATMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241106093900/client/pages/auth/social/[organisation]/[provider]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93L9tQp3lH3vMeta?.name ?? "defects-id",
    path: _91id_93L9tQp3lH3vMeta?.path ?? "/defects/:id()",
    children: [
  {
    name: defectActivitiesVNGXDsgQpxMeta?.name ?? "defects-id-defectActivities",
    path: defectActivitiesVNGXDsgQpxMeta?.path ?? "defectActivities",
    meta: defectActivitiesVNGXDsgQpxMeta || {},
    alias: defectActivitiesVNGXDsgQpxMeta?.alias || [],
    redirect: defectActivitiesVNGXDsgQpxMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241106093900/client/pages/defects/[id]/defectActivities.vue").then(m => m.default || m)
  },
  {
    name: defectBaseDataAkWmEsD5fXMeta?.name ?? "defects-id-defectBaseData",
    path: defectBaseDataAkWmEsD5fXMeta?.path ?? "defectBaseData",
    meta: defectBaseDataAkWmEsD5fXMeta || {},
    alias: defectBaseDataAkWmEsD5fXMeta?.alias || [],
    redirect: defectBaseDataAkWmEsD5fXMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241106093900/client/pages/defects/[id]/defectBaseData.vue").then(m => m.default || m)
  }
],
    meta: _91id_93L9tQp3lH3vMeta || {},
    alias: _91id_93L9tQp3lH3vMeta?.alias || [],
    redirect: _91id_93L9tQp3lH3vMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241106093900/client/pages/defects/[id].vue").then(m => m.default || m)
  },
  {
    name: indexCNDEyx1Hz3Meta?.name ?? "index",
    path: indexCNDEyx1Hz3Meta?.path ?? "/",
    meta: indexCNDEyx1Hz3Meta || {},
    alias: indexCNDEyx1Hz3Meta?.alias || [],
    redirect: indexCNDEyx1Hz3Meta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241106093900/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93EdmymAoZvzMeta?.name ?? "reports-id",
    path: _91id_93EdmymAoZvzMeta?.path ?? "/reports/:id()",
    children: [
  {
    name: reportActivities6VqECq7ahFMeta?.name ?? "reports-id-reportActivities",
    path: reportActivities6VqECq7ahFMeta?.path ?? "reportActivities",
    meta: reportActivities6VqECq7ahFMeta || {},
    alias: reportActivities6VqECq7ahFMeta?.alias || [],
    redirect: reportActivities6VqECq7ahFMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241106093900/client/pages/reports/[id]/reportActivities.vue").then(m => m.default || m)
  },
  {
    name: reportBaseDataIEUXe7j3Z4Meta?.name ?? "reports-id-reportBaseData",
    path: reportBaseDataIEUXe7j3Z4Meta?.path ?? "reportBaseData",
    meta: reportBaseDataIEUXe7j3Z4Meta || {},
    alias: reportBaseDataIEUXe7j3Z4Meta?.alias || [],
    redirect: reportBaseDataIEUXe7j3Z4Meta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241106093900/client/pages/reports/[id]/reportBaseData.vue").then(m => m.default || m)
  },
  {
    name: reportDefectsUDkz1G2KmAMeta?.name ?? "reports-id-reportDefects",
    path: reportDefectsUDkz1G2KmAMeta?.path ?? "reportDefects",
    meta: reportDefectsUDkz1G2KmAMeta || {},
    alias: reportDefectsUDkz1G2KmAMeta?.alias || [],
    redirect: reportDefectsUDkz1G2KmAMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241106093900/client/pages/reports/[id]/reportDefects.vue").then(m => m.default || m)
  }
],
    meta: _91id_93EdmymAoZvzMeta || {},
    alias: _91id_93EdmymAoZvzMeta?.alias || [],
    redirect: _91id_93EdmymAoZvzMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241106093900/client/pages/reports/[id].vue").then(m => m.default || m)
  },
  {
    name: settingsSE5J78pnFJMeta?.name ?? "settings",
    path: settingsSE5J78pnFJMeta?.path ?? "/settings",
    children: [
  {
    name: languageDQjyJMXCV7Meta?.name ?? "settings-language",
    path: languageDQjyJMXCV7Meta?.path ?? "language",
    meta: languageDQjyJMXCV7Meta || {},
    alias: languageDQjyJMXCV7Meta?.alias || [],
    redirect: languageDQjyJMXCV7Meta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241106093900/client/pages/settings/language.vue").then(m => m.default || m)
  },
  {
    name: _91id_93NNPLcI6MyOMeta?.name ?? "settings-loginProviders-id",
    path: _91id_93NNPLcI6MyOMeta?.path ?? "loginProviders/:id()",
    meta: _91id_93NNPLcI6MyOMeta || {},
    alias: _91id_93NNPLcI6MyOMeta?.alias || [],
    redirect: _91id_93NNPLcI6MyOMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241106093900/client/pages/settings/loginProviders/[id].vue").then(m => m.default || m)
  },
  {
    name: indexcQYpeyMYA3Meta?.name ?? "settings-loginProviders",
    path: indexcQYpeyMYA3Meta?.path ?? "loginProviders",
    meta: indexcQYpeyMYA3Meta || {},
    alias: indexcQYpeyMYA3Meta?.alias || [],
    redirect: indexcQYpeyMYA3Meta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241106093900/client/pages/settings/loginProviders/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93UBEU2hr2mgMeta?.name ?? "settings-organisations-id",
    path: _91id_93UBEU2hr2mgMeta?.path ?? "organisations/:id()",
    children: [
  {
    name: organisationBaseDataMx7A6vkD05Meta?.name ?? "settings-organisations-id-organisationBaseData",
    path: organisationBaseDataMx7A6vkD05Meta?.path ?? "organisationBaseData",
    meta: organisationBaseDataMx7A6vkD05Meta || {},
    alias: organisationBaseDataMx7A6vkD05Meta?.alias || [],
    redirect: organisationBaseDataMx7A6vkD05Meta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241106093900/client/pages/settings/organisations/[id]/organisationBaseData.vue").then(m => m.default || m)
  }
],
    meta: _91id_93UBEU2hr2mgMeta || {},
    alias: _91id_93UBEU2hr2mgMeta?.alias || [],
    redirect: _91id_93UBEU2hr2mgMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241106093900/client/pages/settings/organisations/[id].vue").then(m => m.default || m)
  },
  {
    name: indexfwBTTEMlhsMeta?.name ?? "settings-organisations",
    path: indexfwBTTEMlhsMeta?.path ?? "organisations",
    meta: indexfwBTTEMlhsMeta || {},
    alias: indexfwBTTEMlhsMeta?.alias || [],
    redirect: indexfwBTTEMlhsMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241106093900/client/pages/settings/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: rolesUoejTZpSnhMeta?.name ?? "settings-roles",
    path: rolesUoejTZpSnhMeta?.path ?? "roles",
    meta: rolesUoejTZpSnhMeta || {},
    alias: rolesUoejTZpSnhMeta?.alias || [],
    redirect: rolesUoejTZpSnhMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241106093900/client/pages/settings/roles.vue").then(m => m.default || m)
  },
  {
    path: usersn0J7foJK4nMeta?.path ?? "users",
    children: [
  {
    name: _91id_93G0gY2zIqEqMeta?.name ?? "settings-users-id",
    path: _91id_93G0gY2zIqEqMeta?.path ?? ":id()",
    children: [
  {
    name: userActivitieszssLT4inAFMeta?.name ?? "settings-users-id-userActivities",
    path: userActivitieszssLT4inAFMeta?.path ?? "userActivities",
    meta: userActivitieszssLT4inAFMeta || {},
    alias: userActivitieszssLT4inAFMeta?.alias || [],
    redirect: userActivitieszssLT4inAFMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241106093900/client/pages/settings/users/[id]/userActivities.vue").then(m => m.default || m)
  },
  {
    name: userBaseDataaAB7OxVEaCMeta?.name ?? "settings-users-id-userBaseData",
    path: userBaseDataaAB7OxVEaCMeta?.path ?? "userBaseData",
    meta: userBaseDataaAB7OxVEaCMeta || {},
    alias: userBaseDataaAB7OxVEaCMeta?.alias || [],
    redirect: userBaseDataaAB7OxVEaCMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241106093900/client/pages/settings/users/[id]/userBaseData.vue").then(m => m.default || m)
  },
  {
    name: userCommunicationsR2tsMWpobDMeta?.name ?? "settings-users-id-userCommunications",
    path: userCommunicationsR2tsMWpobDMeta?.path ?? "userCommunications",
    meta: userCommunicationsR2tsMWpobDMeta || {},
    alias: userCommunicationsR2tsMWpobDMeta?.alias || [],
    redirect: userCommunicationsR2tsMWpobDMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241106093900/client/pages/settings/users/[id]/userCommunications.vue").then(m => m.default || m)
  }
],
    meta: _91id_93G0gY2zIqEqMeta || {},
    alias: _91id_93G0gY2zIqEqMeta?.alias || [],
    redirect: _91id_93G0gY2zIqEqMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241106093900/client/pages/settings/users/[id].vue").then(m => m.default || m)
  },
  {
    name: indexOWuC1A3DBqMeta?.name ?? "settings-users",
    path: indexOWuC1A3DBqMeta?.path ?? "",
    meta: indexOWuC1A3DBqMeta || {},
    alias: indexOWuC1A3DBqMeta?.alias || [],
    redirect: indexOWuC1A3DBqMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241106093900/client/pages/settings/users/index.vue").then(m => m.default || m)
  }
],
    name: usersn0J7foJK4nMeta?.name ?? undefined,
    meta: usersn0J7foJK4nMeta || {},
    alias: usersn0J7foJK4nMeta?.alias || [],
    redirect: usersn0J7foJK4nMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241106093900/client/pages/settings/users.vue").then(m => m.default || m)
  }
],
    meta: settingsSE5J78pnFJMeta || {},
    alias: settingsSE5J78pnFJMeta?.alias || [],
    redirect: settingsSE5J78pnFJMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241106093900/client/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93EyMchwL6wcMeta?.name ?? "wiki-slug",
    path: _91_46_46_46slug_93EyMchwL6wcMeta?.path ?? "/wiki/:slug(.*)*",
    meta: _91_46_46_46slug_93EyMchwL6wcMeta || {},
    alias: _91_46_46_46slug_93EyMchwL6wcMeta?.alias || [],
    redirect: _91_46_46_46slug_93EyMchwL6wcMeta?.redirect || undefined,
    component: () => import("/home/forge/app.eis-s.de/releases/20241106093900/client/pages/wiki/[...slug].vue").then(m => m.default || m)
  }
]